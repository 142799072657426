<template>
  <div class="card">
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="headerCellStyle">
      <el-table-column
        label="订单编号"
        prop="orderNo">
      </el-table-column>
      <el-table-column
        label="服务提供方"
        prop="sellerName">
      </el-table-column>
      <el-table-column
        label="服务需求方"
        prop="buyerName">
      </el-table-column>
      <el-table-column
        label="价格"
        prop="totalAmount">
        <template slot-scope="scope">
          <p
            class="text_size_14 tw-font-bold"
            style="color:#303133;">
            {{ scope.row.totalAmount > 0 ? scope.row.totalAmount : '面议' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="评价时间"
        prop="createdAt">
      </el-table-column>
      <el-table-column
        label="操作"
        width="80">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="onComment(scope.row)">
            评价详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="card_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>

    <comment-dialog
      :visible.sync="commentDialog.visible"
      :feedback-id="commentDialog.feedbackId">
    </comment-dialog>
  </div>
</template>

<script>
import * as api from '@/api'
import commentDialog from './comment-dialog'

export default {
  components: {
    commentDialog
  },
  data () {
    return {
      loading: false,
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }, // 分页对象
      commentDialog: {
        visible: false,
        feedbackId: ''
      }
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    getTableData (pageNum) {
      this.loading = true
      api.getOrderCommentList({
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onComment (row) {
      this.commentDialog.visible = true
      this.commentDialog.feedbackId = row.id
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 980px;
  padding: 30px 40px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_search {
    margin-bottom: 20px;
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
