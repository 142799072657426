<template>
  <el-dialog
    title="订单评价"
    :visible.sync="$visible"
    width="570px"
    :show-close="false"
    @open="onOpen">
    <div class="tw-mx-20px">
      <el-form
        :model="formData"
        :disabled="true"
        ref="form"
        size="small"
        label-position="left"
        label-width="110px">
        <el-form-item
          label="服务质量"
          prop="serviceQuality">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceQuality" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="服务效果"
          prop="serviceEffect">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceEffect" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="服务态度"
          prop="serviceAttitude">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceAttitude" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="评价内容"
          prop="content">
          <el-input
            v-model="formData.content"
            type="textarea" maxlength="300" show-word-limit
            :rows="4">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button
        size="small"
        @click="onCancel">
        取消
      </el-button>
      <el-button
        v-if="formData.logicStatus === 0"
        type="primary"
        size="small"
        :loading="btnLoading"
        @click="onConfirm">
        通过
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    feedbackId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formData: {
        serviceQuality: 5, // 服务质量
        serviceEffect: 5, // 服务效果
        serviceAttitude: 5, // 服务态度
        content: '', // 评价内容
        logicStatus: 1 // 0 - 不通过，1 - 通过
      },
      btnLoading: false
    }
  },
  computed: {
    $visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onOpen () {
      this.getCommentInfo(this.feedbackId)
    },
    getCommentInfo (feedbackId) {
      api.getOrderCommentInfo(feedbackId).then(res => {
        if (res.data.code === 0) {
          for (const key in this.formData) {
            if (Object.hasOwnProperty.call(this.formData, key)) {
              if (key === 'content') {
                this.formData[key] = res.data.data[key]
              } else {
                this.formData[key] = res.data.data[key] / 2
              }
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onCancel () {
      this.$visible = false
    },
    onConfirm () {
      this.btnLoading = true
      api.passOrderComment(this.feedbackId).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.onCancel()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
